/**
 * Extend `.btn {}` in _buttons-elements.scss, modify in _buttons-modifiers.scss
 */

.btn {
  display:inline-block;
  zoom:1;
  vertical-align:bottom;
  text-align:center;
  margin:10px 5px;
  border-radius:3px;
  text-decoration:none;
  font-weight:900;
  font-family:'Helvetica Neue', Helvetica, Arial, sans-serif;
  text-shadow:0 1px 1px rgba(0,0,0,0.5);
  box-shadow:0 1px 3px rgba(0,0,0,0.3),inset 0 1px 0 rgba(250,250,250,0.4);
  -webkit-box-shadow:0 1px 3px rgba(0,0,0,0.3),inset 0 1px 0 rgba(250,250,250,0.4);
  -moz-box-shadow:0 1px 3px rgba(0,0,0,0.3),inset 0 1px 0 rgba(250,250,250,0.4);
}
