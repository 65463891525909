//@font-face {
//  font-family: 'Material Icons';
//  font-style: normal;
//  font-weight: 400;
//  src: url(https://example.com/MaterialIcons-Regular.eot); /* For IE6-8 */
//  src: local('Material Icons'),
//  local('MaterialIcons-Regular'),
//  url(https://example.com/MaterialIcons-Regular.woff2) format('woff2'),
//  url(https://example.com/MaterialIcons-Regular.woff) format('woff'),
//  url(https://example.com/MaterialIcons-Regular.ttf) format('truetype');
//}
@font-face {
  font-family: 'Font Awesome 5 Brands';
  font-style: normal;
  font-weight: normal;
  src: url("../../css/webfonts/fa-brands-400.eot");
  src: url("../../css/webfonts/fa-brands-400.eot?#iefix") format("embedded-opentype"),
       url("../../css/webfonts/fa-brands-400.woff2") format("woff2"),
       url("../../css/webfonts/fa-brands-400.woff") format("woff"),
       url("../../css/webfonts/fa-brands-400.ttf") format("truetype"),
       url("../../css/webfonts/fa-brands-400.svg#fontawesome") format("svg");
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px; /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  -webkit-font-smoothing: antialiased; /* Support for all WebKit browsers. */
  text-rendering: optimizeLegibility; /* Support for Safari and Chrome. */
  -moz-osx-font-smoothing: grayscale; /* Support for Firefox. */
  /* Support for IE. */
  font-feature-settings: 'liga';
}

