// Grid 960.cs helper class to show grids' outline
/* Helpers
-------------------------------------------------------------- */
.show-grid div[class*='grid-'] {
  background-color: #eee;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
  //padding: 0.5em 1em;
  margin-bottom: 1em;
  text-align: center;
  border: 1px darkgray solid;
}


//Basic helpers
.hidden {
  display: none;
}

.marginBottom--none{
  margin-bottom: 0 !important;
}
.marginBottom--5{
  margin-bottom: 5% !important;
}
.marginBottom--10 {
  margin-bottom: 10% !important;
}
