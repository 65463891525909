@import "../variables/colors";
$font_SansSerif: sans-serif;


/* Default is NIGHTTIME, offset by jQuery based on the time of day; however, you can click on the greeting to toggle between day/night.*/

div#parallax {
  margin: 0 auto;
  background-color: $nightTimeBackground;
  overflow: hidden;
  height: 500px;
  position: relative;
  z-index: 1;
  left: 50%;
  transform: translate(-50%, 0%);

  /*parallax scroll*/
  background-attachment: fixed;
  background-position: center;
  background-repeat: repeat;
  background-size: cover;
}

#bg {
  background-color: $daytimebackground;
  width: 100px;
  height: 100px;
  position: absolute;
  top: 100px;
  left: calc(50% - 50px);
  border-radius: 50%;
  transition: all 0.7s ease;

  &:before {
    content: "";
    width: 200px;
    height: 200px;
    border-radius: 50%;
    background-color: rgba(173, 216, 230, 0.1);
    position: absolute;
    left: -50px;
    top: -50px;
  }
}

.planet {
  margin: 0 auto;
  width: 300px;
  height: 300px;
  background-color: $moon_baseColor;
  display: block;
  border-radius: 50%;
  position: relative;
  box-shadow: inset -40px 0px 0px $moon_secondShadow,
              inset 20px 0px 0px $moon_highlight,
              inset -50px 0px 0px 20px $moon_firstShadow,
              0px 0px 0px 20px rgba(255, 255, 255, 0.05),
              0px 0px 0px 40px rgba(255, 255, 255, 0.025),
              0px 0px 0px 60px rgba(255, 255, 255, 0.0125);
  border: solid 10px;
  transition: all 0.2s ease-in;
  top: 10%;

  &:after {
    content: "";
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: $moon_firstShadow;
    position: absolute;
    top: 20%;
    left: 20%;
    box-shadow: 40px -20px 0px -10px $moon_firstShadow,
                40px 10px 0px -15px $moon_firstShadow;
  }
}

.face {
  position: absolute;
  width: 100px;
  top: 50%;
  left: 40%;
}

.eye {
  width: 20px;
  height: 20px;
  display: block;
  float: left;
  margin-right: 8px;
  position: relative;
}

.eye-in {
  width: 15px;
  height: 8px;
  display: block;
  position: absolute;
  top: 5px;
  left: -1px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  border: 5px solid $nightTimeBackground;
  border-top: 0;
}

.mouth {
  margin-top: 15px;
  height: 25px;
  width: 25px;
  display: block;
  border-radius: 50%;
  background-color: $color_black;
  float: left;
  border: none;
  border-top: 0;
  margin-right: 10px;
  margin-left: 2px;
  position: relative;

  animation-name: mouth-sleep;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-out;
}

.pos-star1 {
  top: 10%;
  left: 30%;
}

.pos-star2 {
  top: 70%;
  right: 40%;
}

.pos-star3 {
  top: 50%;
  left: 35%;
}

.star {
  width: 20px;
  height: 20px;
  background-color: $color_white;
  display: block;
  border-radius: 50%;
  float: left;
  position: absolute;
  transform-origin: 50% 50%;
  animation-name: star3;
  animation-duration: 20s;
  animation-iteration-count: infinite;
  animation-timing-function: ease;
  transition: all 0.3s ease-out;

  &:before {
    width: 20px;
    height: 20px;
    content: "";
    background-color: $color_white;
    position: absolute;
    transform: scale(1, 2) rotate(45deg);
    animation-name: star1;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-out;
    border-radius: 20%;
    transition: all 0.3s ease-out;
  }

  &:after {
    width: 20px;
    height: 20px;
    content: "";
    background-color: $color_white;
    position: absolute;
    transform: scale(2, 1) rotate(45deg);
    animation-name: star2;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-out;
    animation-delay: 0.2s;
    border-radius: 20%;
    transition: all 0.3s ease-out;
  }
}

#greeting {
  border: 2px solid #f1c40f;
  padding: 20px 20px;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 3px;
  color: #fff;
  text-decoration: none;
  position: absolute;
  display: block;
  text-align: center;
  background: $nightTimeBackground;
  text-shadow: none;
  font-family: sans-serif;
  margin: 0 auto;
  bottom: 10%;
  border-radius: 100px;
  max-width: 200px;
  left: 0;
  right: 0;
  transition: all 0.2s ease;

  &:active {
    transform: scale(0.95);
    transition: all 0.2s ease;
  }
}

.night #greeting:after {
  content: "Good Evening!";
}

.zzz1,
.zzz2,
.zzz3 {
  color: $color_white;
  position: absolute;
  top: 0;
  transform: translate(-50%, 30%);
  font-size: 36px;
  opacity: 0;
  font-family: $font_SansSerif;
  animation-name: zzz-sleep;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  z-index: 10;
}

.zzz1:after,
.zzz2:after,
.zzz3:after {
  content: "z";
}

.zzz1 {
  animation-delay: 0s;
  right: 38%;
  transform: translate(-50%, 30%);
}

.zzz2 {
  animation-delay: 1.2s;
  right: 39%;
  top: 3%;
}

.zzz3 {
  animation-delay: 2.4s;
  right: 40%;
  top: 6%;
}

.day {
  .mouth {
    margin-top: 20px;
    height: 15px;
    width: 15px;
    display: block;
    border-radius: 0 0 50% 50%;
    float: left;
    border: 6px solid $color_black;
    margin-right: 10px;
    position: relative;
    margin-left: 0;
    animation-name: none;
    &:after {
      content: "";
      width: 6px;
      height: 6px;
      position: absolute;
      display: block;
      top: -3px;
      left: -6px;
      background-color: $color_black;
      border-radius: 50%;
    }
    &:before {
      content: "";
      width: 6px;
      height: 6px;
      position: absolute;
      display: block;
      top: -3px;
      left: 15px;
      background-color: $color_black;
      border-radius: 50%;
    }
  }
  .eye {
    width: 20px;
    height: 20px;
    display: block;
    float: left;
    margin-right: 10px;
    animation-name: eye-blink;
    animation-duration: 10s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-out;
    position: relative;
    overflow: hidden;
  }
  .eye-in {
    content: "";
    width: 20px;
    height: 20px;
    background-color: $color_black;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 50%;
    transform: scale(1, 1) !important;
    border: none;
  }
  #greeting {
    border: 2px solid #ff8603;
    color: $color_white;
    background: #ff8603;
    &:after {
      content: "Good Day!";
    }
  }
  .planet {
    background-color: $sun_baseColor !important;
    box-shadow: inset -40px 0px 0px $sun_secondShadow,
                inset 20px 0px 0px $sun_highlight,
                inset -50px 0px 0px 20px $sun_firstShadow,
                0px 0px 0px 20px rgba(255, 210, 1, 0.2),
                0px 0px 0px 40px rgba(255, 210, 1, 0.1),
                0px 0px 0px 60px rgba(255, 210, 1, 0.05);
    &:after {
      background-color: $sun_firstShadow;
      box-shadow: 40px -20px 0px -10px $sun_firstShadow,
                  40px 10px 0px -15px $sun_firstShadow;
    }
  }

  .star {
    &:after {
      animation-name: none;
      transform: none;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      left: 150%;
      box-shadow: -50px -25px 0px 10px $color_white,
                  -10px -25px 0px -5px $color_white,
                  inset -7px 0px 0px 3px $moon_baseColor;
    }
    &:before {
      animation-name: none;
      transform: none;
      height: 50px;
      border-radius: 50%;
      left: 50%;
      top: 0;
      width: 75px;
    }
    width: 50px;
    height: 50px;
    animation-name: wiggle;
    animation-duration: 10s;
  }
  #bg {
    transform: scale(100);
  }
  .zzz1 {
    &:after {
      content: "!";
    }
    color: $color_white;
    top: 0;
    right: 40%;
    animation-name: zzz-awake;
    animation-duration: 1s;
    animation-iteration-count: 1;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
    opacity: 1;
  }
  .zzz2 {
    &:after {
      content: "!";
    }
    color: $color_white;
    right: 40%;
    animation-name: zzz-awake;
    animation-duration: 1s;
    animation-iteration-count: 1;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
    opacity: 1;
    animation-delay: 0.2s;
    top: 3%;
  }
  .zzz3 {
    &:after {
      content: "!";
    }
    color: $color_white;
    right: 40%;
    animation-name: zzz-awake;
    animation-duration: 1s;
    animation-iteration-count: 1;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
    opacity: 1;
    animation-delay: 0.4s;
    top: 6%;
  }
}

@keyframes star1 {
  0% {
    transform: scale(1, 2) rotate(45deg);
  }

  50% {
    transform: scale(1, 1) rotate(45deg);
  }

  100% {
    transform: scale(1, 2) rotate(45deg);
  }
}

@keyframes star2 {
  0% {
    transform: scale(2, 1) rotate(45deg);
  }

  50% {
    transform: scale(1, 1) rotate(45deg);
  }

  100% {
    transform: scale(2, 1) rotate(45deg);
  }
}

@keyframes star3 {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes eye-blink {
  0% {
    transform: scale(1, 1)
  }

  2% {
    transform: scale(1, 0)
  }

  4% {
    transform: scale(1, 1)
  }
}

@keyframes mouth-sleep {
  0% {
    transform: scale(1, 1)
  }

  35% {
    transform: scale(0.7, 0.7)
  }

  40% {
    transform: scale(0.7, 0.7)
  }

  75% {
    transform: scale(1, 1)
  }
}

@keyframes wiggle {
  0% {
    transform: translate(0px, 0px)
  }

  50% {
    transform: translate(50px, 0px)
  }

  100% {
    transform: translate(0px, 0px)
  }
}

@keyframes zzz-sleep {
  0% {
    transform: translate(0px, 0px) scale(1, 1);
    opacity: 0;
  }

  25% {
    transform: translate(-5px, -10px) scale(1.1, 1.1);
  }

  50% {
    transform: translate(8px, -20px) scale(0.9, 0.9);
    opacity: 1;
  }

  75% {
    transform: translate(-15px, -30px) scale(1.1, 1.1);
  }

  100% {
    transform: translate(15px, -40px) scale(0.9, 0.9);
    opacity: 0;
  }
}

@keyframes zzz-awake {
  0% {
    transform: translate(0px, 0px) scale(1, 1) rotate(0deg);
  }

  100% {
    transform: translate(-15px, -30px) scale(2, 2) rotate(10deg);
    opacity: 0;
  }
}
