@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,700);

// Variables
$regal-blue: #034378;
$san-juan: #2d4e68;
$bermuda: #77d7b9;
$white: #fff;
$black: #000;
$open-sans: 'Open Sans',
sans-serif;
// clear-fix mixin
@mixin contentfill {
  &::before,
  &::after {
    content: '';
    display: table;
  }
  &::after {
    clear: both;
  }
}

@mixin dark-overlay {
  &:after {
    content: "";
    height: auto;
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
  }
}

#tvShowList {
  background: whitesmoke;
}

.row {
  max-width: 200px;
  margin: 50px auto 0;
}

// Base styles
.card {
  float: left;
  width: 200px;
  margin: 10px;

  .menu-content {
    @include contentfill;
    margin: 0;
    padding: 0;
    list-style-type: none;

    li {
      display: inline-block;
    }

    a {
      color: $white;
      text-decoration: none;
    }

    span {
      position: absolute;
      left: 50%;
      top: 0;
      font-size: 10px;
      font-weight: 700;
      font-family: 'Open Sans';
      transform: translate(-50%, 0);
    }
  }

  .wrapper {
    background-color: $white;
    height: 300px;
    position: relative;
    overflow: hidden;
    box-shadow: 0 19px 38px rgba($black, 0.4), 0 15px 12px rgba($black, 0.9);
    z-index: 2;
    @include dark-overlay;

    &:hover {
      .data {
        transform: translateY(0);
      }
    }
    &:after{
      background:rgba(0, 0, 0, 0.25);
    }
  }

  .data {
    position: absolute;
    bottom: 0;
    top: 36%;
    width: 100%;
    transform: translateY(calc(70px + 1em));
    transition: transform 0.3s;
    text-shadow: 1px 1px #000000;

    .content {
      padding: 0 10px;
      position: relative;
      z-index: 2;
    }
  }

  .status {
    font-size: 12px;
  }

  .title {
    margin-bottom: 0%;
    min-height: 50px;
    text-shadow: 1px 1px #000000;
  }

  .text {
    max-height: 110px;
    margin: 0;
    font-size: 0.9em;
    overflow-y: auto;
    word-wrap: break-word;
    text-shadow: 1px 1px #000000;
    background-color: rgba(0 ,0 ,0, 0.5);
  }

  input[type='checkbox'] {
    display: none;
  }

  input[type='checkbox']:checked + .menu-content {
    transform: translateY(-60px);
  }
}

.showCard {
  .wrapper {
    &:hover {
      .menu-content {
        span {
          transform: translate(-50%, -10px);
          opacity: 1;
        }
      }
    }
  }

  .header {
    @include contentfill;
    float: left;
    color: $white;
    padding: 1em;
    z-index: 3;

    .date {
      float: left;
      font-size: 12px;
      min-width: 100px;
    }
  }

  .menu-content {
    float: right;

    li {
      margin: 0 5px;
      position: relative;
    }

    span {
      transition: all 0.3s;
      opacity: 0;
    }
  }

  .data {
    color: $white;
    transform: translateY(calc(70px + 7.2em));
    z-index: 3;
  }

  .title {
    a {
      color: $white;
    }
  }

  //.button {
  //  display: block;
  //  width: 100px;
  //  margin: 2em auto 1em;
  //  text-align: center;
  //  font-size: 12px;
  //  color: $white;
  //  line-height: 1;
  //  position: relative;
  //  font-weight: 700;
  //
  //  &::after {
  //    content: '\2192';
  //    opacity: 0;
  //    position: absolute;
  //    right: 0;
  //    top: 50%;
  //    transform: translate(0, -50%);
  //    transition: all 0.3s;
  //  }
  //
  //  &:hover {
  //    &::after {
  //      transform: translate(5px, -50%);
  //      opacity: 1;
  //    }
  //  }
  //}
}
