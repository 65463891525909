$color_white: white;
$color_black: black;
$nightTimeBackground: midnightblue;
$moon_baseColor: #ebf3fe;
$moon_highlight: #fafdff;
$moon_firstShadow: #d3e5f8;
$moon_secondShadow: #aacdee;
$daytimebackground: paleturquoise;
$sun_baseColor: #ffdb01;
$sun_highlight: #fff4b7;
$sun_firstShadow: #ffd201;
$sun_secondShadow: #ff8603;
$background_color_8: rgba(173, 216, 230, 0.1);
